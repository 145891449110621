.mbsc-timeline-grid-scroll {
  height: calc(100vh - 219px) !important;
}

.mbsc-timeline-resource-col,
.mbsc-timeline-resource {
  width: 120px !important;
}

.mbsc-timeline-resource-title {
  margin-left: 30px;
  margin-top: 9px;
}

.md-timeline-template .mbsc-schedule-event.mbsc-ltr {
  height: auto !important;
}

.md-timeline-template-event {
  //   border: 1px solid transparent;
  margin: 2px 0;
}

.md-timeline-template-event-cont {
  background: rgba(255, 255, 255, 0.8);
  font-size: 12px;
  height: 38px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.md-timeline-template-event-cont .mbsc-icon {
  padding: 5px;
  box-sizing: content-box;
}

.mbsc-timeline-event-start .md-timeline-template-event,
.mbsc-timeline-event-start .md-timeline-template-event-cont,
.mbsc-timeline-event-start .md-timeline-template-event-cont .mbsc-icon {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.mbsc-timeline-event-end .md-timeline-template-event,
.mbsc-timeline-event-end .md-timeline-template-event-cont,
.mbsc-timeline-event-end .md-timeline-template-event-cont .mbsc-icon {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.md-timeline-template-event-cont .mbsc-icon:before {
  color: #fff;
  font-size: 18px;
}

.md-timeline-template-time {
  margin: 0 10px;
  color: rgb(58, 58, 58);
}

.md-timeline-template-title-hazard {
  color: red;
}

.md-timeline-template-title {
  color: #000;
  font-size: 13px;
}

.md-timeline-template .mbsc-timeline-column,
.md-timeline-template .mbsc-timeline-header-column {
  min-width: 100px;
}

.md-timeline-template .mbsc-timeline-resource,
.md-timeline-template .mbsc-timeline-row {
  min-height: 100px;
}

.mbsc-ios.mbsc-calendar-button.mbsc-button,
.mbsc-calendar-button-prev,
.mbsc-calendar-button-next {
  color: #009cde !important;
}

.mbsc-ios.mbsc-schedule-header-day.mbsc-selected {
  background: #009cde !important;
}

.reservation-timeline {
  .mbsc-calendar-button-prev,
  .mbsc-calendar-header-today,
  .mbsc-calendar-button-next {
    // display: none !important;
  }
}

.mbsc-schedule-color {
  background-color: transparent !important;
}

.mbsc-timeline-row:nth-child(odd) {
  background: #f1efef;
}

.mbsc-timeline-row:nth-child(even) {
}
