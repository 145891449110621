@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/brands";
@import "~@fortawesome/fontawesome-free/scss/solid";

@import "variables.scss";
@import "theme/custom-material-ui.scss";
@import "theme/custom-mat-table.scss";
@import "theme/custom-mat-checkbox.scss";
@import "theme/custom-mat-tab.scss";
@import "theme/custom-mat-slide-toggle.scss";
@import "theme/custom-sidenav.scss";
@import "theme/custom-mbsc-datepicker.scss";
@import "theme/custom-mbsc-calendar.scss";
@import "theme/custom-mbsc-timeline.scss";
@import "theme/custom-mbsc-tooltip.scss";
@import "theme/custom-mbsc-recurring-reservation.scss";
@import "theme/custom-mat-dialog.scss";
@import "theme/custom-cdk-overlay.scss";
@import "theme/custom-mat-select.scss";
@import "theme/custom-mat-expension.scss";
@import "theme/custom-mapbox.scss";

@import "loading-spinner.scss";

@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i");

@import url("mapbox-gl/dist/mapbox-gl.css");

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
}

textarea:focus {
  border: none;
  background-color: transparent;
  resize: none;
  outline: none;
  box-shadow: none;
}

app-page-title {
  & ~ div {
    margin-top: 54px;
  }
}
