.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: #478b47 !important;
}

.mat-checkbox-label {
  color: #777777;
}

.timeline-search {
  .mat-checkbox-label {
    color: rgb(55, 65, 81);
    font-size: 14px !important;
    line-height: 1.25rem !important;
    font-family: "Open Sans";
  }
}
