mat-select {
  padding: 0px !important;
  background-color: transparent !important;
  border: 0px;

  & + span {
    mat-label {
      font-size: 16px;
    }
  }
}
